import { Box } from "@mui/material";
import React from "react";

interface Props {
    name: string;
    content: React.ReactElement<any, string>;
    footer: React.ReactElement<any, string>;
    backgroundImage: string;
    side: string;
}

export default function ActivityInfo(children: Props) {
    return <Box component="section" id={children.name} sx={{
        backgroundImage: `${children.backgroundImage}`,
        backgroundSize: 'cover',
        height: '60rem',
        '& .left': {
            borderRight: {
                md: '3px solid #FDFFFF'
            },
            left: 0,
            // left: '-100%',
            transition: 'left 2s',
        },
        '& .right': {
            borderLeft: {
                md: '3px solid #FDFFFF'
            },
            right: 0,
            // right: '-100%',
            transition: 'right 2s',
        },
        '&.visible': {
            '.left': {
                left: 0
            },
            '.right': {
                right: 0
            }
        },
        '.left, .right': {
            backgroundColor: 'rgba(54, 54, 54, .75)',
            padding: '10px 30px',
            height: '60rem',
            width: {
                xs: '100%',
                md: '70%',
                lg: '30%',
            },
            overflow: 'auto',
            position: 'absolute',
            'h3': {
                borderBottom: '2px solid #D81D2C',
                marginTop: '10px'
            },
            'table': {
                borderCollapse: 'collapse',
                marginBottom: '20px',
                fontSize: '.8em',
                'td': {
                    borderTop: '1px solid #DDDDDD',
                    padding: '8px',
                    verticalAlign: 'top'
                },
                'tr:first-child td': {
                    border: 'none'
                }
            }
        }
    }}>
        <div className={children.side}>
            {children.content}
        </div>

        {/*{children.footer}*/}
    </Box>;
}
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import axios from "axios";
import Home from "./pages/Home";
import Shows from "./pages/Shows";
import Appointments from "./pages/Appointments";
import './i18n/config';
import Appointment from "./pages/Appointment";

const container = document.getElementById('root');
const root = createRoot(container!);

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/private/api`;

root.render(
    <React.StrictMode>
        <Suspense>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/shows" element={<Shows/>}/>
                        <Route path="/appointment" element={<Appointments/>}/>
                        <Route path="/appointment/:id" element={<Appointment/>}/>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

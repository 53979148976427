import axios from "axios";
import moment from "moment";
import {TimeslotResource} from "./TimeslotResource";
import {AvailabilityResource} from "./AvailabilityResource";
import {Availability} from "@timtowtdi/wata.domain.wata";

export const getAvailabilitiesRequest = async (serviceName: string | undefined): Promise<Availability[]> => {
    let url;

    if (serviceName === undefined) {
        url = `availabilities.php`;
    } else {
        url = `availabilities.php?service_name=${serviceName}`;
    }

    const {data} = await axios.get(url);

    return (data || []).map((availabilityResource: AvailabilityResource): Availability => {
        return {
            date: moment(availabilityResource.date),
            serviceName: availabilityResource.service_name,
            timeslots: availabilityResource.timeslots.map((timeslotResource: TimeslotResource) => {
                return {
                    time: moment(timeslotResource.time, 'HH:mm:ss'),
                    capacity: timeslotResource.capacity
                }
            })
        }
    });
}
import {Box, Button, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {KeyboardArrowDown} from "@mui/icons-material";
import {HashLink} from "react-router-hash-link";
import ActivityInfo from "../components/ActivityInfo";
import Footer from "../components/Footer";
import LanguageSelector from "../components/LanguageSelector";
import BasicAppBar from "../components/BasicAppBar";
import '../index.css';

export default function Home() {
    const {t} = useTranslation();

    let contentFlyboard = <>
        <h2>{t('section_flyboard_title')}</h2>
        <h3>{t('section_subtitle_what')}</h3>
        <p>{t('section_flyboard_paragraph_1')}</p>
        <p>{t('section_flyboard_paragraph_2')}</p>
        <p>{t('section_flyboard_paragraph_3')}</p>
        <p>{t('section_flyboard_paragraph_4')}</p>
        <h3>{t('section_subtitle_price')}</h3>
        <table>
            <tr>
                <td/>
                <td>{t('price_individual')} <span>{t('price_less_than_eight')}</span></td>
                <td>{t('price_group')} <span>{t('price_starting_from_eight')}</span></td>
            </tr>
            <tr>
                <td>{t('duration_flyboard')}</td>
                <td>€ 70</td>
                <td>€ 60</td>
            </tr>
            <tr>
                <td/>
                <td>€ 100</td>
                <td>€ 90</td>
            </tr>
        </table>
        <p>{t('note_price')}</p>
        <Button component={Link} to="/appointment#flyboard"
                variant="contained">{t('__Button_Reserve_Flyboard')}</Button>
    </>;

    let contentHoverboard = <>
        <h2>{t('section_hoverboard_title')}</h2>
        <h3>{t('section_subtitle_what')}</h3>
        <p>{t('section_hoverboard_paragraph_1')}</p>
        <p>{t('section_hoverboard_paragraph_2')}</p>
        <h3>{t('section_subtitle_price')}</h3>
        <table>
            <tr>
                <td/>
                <td>{t('price_individual')} <span>{t('price_less_than_eight')}</span></td>
                <td>{t('price_group')} <span>{t('price_starting_from_eight')}</span></td>
            </tr>
            <tr>
                <td>{t('duration_hoverboard')}</td>
                <td>€ 70</td>
                <td>€ 60</td>
            </tr>
            <tr>
                <td/>
                <td>€ 100</td>
                <td>€ 90</td>
            </tr>
        </table>
        <p>{t('note_price')}</p>
        <Button component={Link} to="/appointment#hoverboard"
                variant="contained">{t('__Button_Reserve_Hoverboard')}</Button>
    </>;

    let contentJetpack = <>
        <h2>{(t('section_jetpack_title'))}</h2>
        <h3>{(t('section_subtitle_what'))}</h3>
        <p>{(t('section_jetpack_paragraph_1'))}</p>
        <h3>{(t('section_subtitle_price'))}</h3>
        <table>
            <tr>
                <td/>
                <td>{t('price_individual')} <span>{t('price_less_than_eight')}</span></td>
                <td>{t('price_group')} <span>{t('price_starting_from_eight')}</span></td>
            </tr>
            <tr>
                <td>{t('duration_jetpack')}</td>
                <td>€ 120</td>
                <td>€ 110</td>
            </tr>
            <tr>
                <td></td>
                <td>€ 150</td>
                <td>€ 140</td>
            </tr>
        </table>
        <p>{t('note_price')}</p>
        <Button component={Link} to="/appointment#jetpack"
                variant="contained">{t('__Button_Reserve_Jetpack')}</Button>
    </>;

    let contentJetovator = <>
        <h2>{t('section_jetovator_title')}</h2>
        <h3>{t('section_subtitle_what')}</h3>
        <p>{(t('section_jetovator_paragraph_1'))}</p>
        <h3>{t('section_subtitle_price')}</h3>
        <table>
            <tr>
                <td/>
                <td>{t('price_individual')} <span>{t('price_less_than_eight')}</span></td>
                <td>{t('price_group')} <span>{t('price_starting_from_eight')}</span></td>
            </tr>
            <tr>
                <td>{t('duration_jetovator')}</td>
                <td>€ 120</td>
                <td>€ 110</td>
            </tr>
            <tr>
                <td></td>
                <td>€ 150</td>
                <td>€ 140</td>
            </tr>
        </table>
        <p>{t('note_price')}</p>
        <Button component={Link} to="/appointment#jetovator"
                variant="contained">{t('__Button_Reserve_Jetovator')}</Button>
    </>;

    let contentBumperball = <>
        <h2>{t('section_bumperball_title')}</h2>
        <h3>{t('section_subtitle_what')}</h3>
        <p>{t('section_bumperball_paragraph_1')}</p>
        <ul>
            <li>{t('section_bumperball_list_item_1')}</li>
            <li>{t('section_bumperball_list_item_2')}</li>
            <li>{t('section_bumperball_list_item_3')}</li>
            <li>{t('section_bumperball_list_item_4')}</li>
            <li>...</li>
        </ul>
        <h3>{t('section_subtitle_where')}</h3>
        <p>{t('section_bumperball_paragraph_2')}</p>
        <h3>{t('section_subtitle_price')}</h3>
        <p>{t('section_bumperball_paragraph_3')}</p>
        <p>{t('section_bumperball_paragraph_4')}</p>
        <Button component={Link} to="/appointment#bumperball"
                variant="contained">{t('__Button_Reserve_Bumperball')}</Button>
    </>;

    return <Box sx={{
        color: '#fdffff',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        // position: 'relative',
        // overflow: 'hidden',
        '& > h3': {
            color: '#D81D2C'
        },
    }}>
        <BasicAppBar/>
        <Box component="section" id="home" sx={{
            height: '60rem',
            '& video': {
                objectFit: 'cover',
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: '-1'
            },
            '& img, h1, h2, p, a': {
                textAlign: 'center'
            },
            'img': {
                display: 'block',
                paddingTop: '40px',
                width: '15%'
            }
        }}>
            <video autoPlay muted loop>
                <source src="/assets/videos/home-web.mp4" type="video/mp4"/>
            </video>

            <Container component="header" sx={{
                margin: '40px auto 0 auto',
                paddingTop: '6%',
                'img': {
                    margin: '0 auto'
                },
                'div': {
                    margin: '0 auto',
                    width: '40%',
                    'img, p': {
                        float: 'left'
                    },
                    'img': {
                        width: '30%'
                    },
                    'p': {
                        padding: '20px',
                        width: '60%'
                    }
                }
            }}>
                <img src="assets/images/logo.png" alt="Flyboard Vlaanderen Logo"/>
                <Typography variant="h1" sx={{
                    fontSize: {
                        xs: '3em',
                        md: '3.8em'
                    },
                    margin: '20px 0'
                }}>{t('section_header_title')}</Typography>
                <Typography variant="h2" sx={{
                    fontSize: '1.5em',
                    margin: '0 auto 30px auto',
                    width: '90%'
                }}>{t('section_header_subtitle')}</Typography>
                <Typography paragraph>
                    <Button variant="contained" component={Link}
                            sx={{m: 1}}
                            to="/appointment">{t('__Header_Button_Reserve')}</Button> {t('__or')} <Button
                    variant="contained" component="a"
                    href={t('__cadeaubon_link')} target="_blank">{t('__Header_Button_Bon')}</Button>
                </Typography>
            </Container>

            {/*<HashLink to="#flyboard" className="navigationItem"><KeyboardArrowDownIcon/></HashLink>*/}
        </Box>
        <ActivityInfo content={contentFlyboard} name="flyboard"
                      backgroundImage="url(assets/images/backgrounds/flyboard.jpg)"
                      side="right" footer={<HashLink to="#hoverboard"
                                                     className="navigationItem"><KeyboardArrowDown/></HashLink>}/>
        <ActivityInfo content={contentHoverboard} name="hoverboard"
                      backgroundImage="url(assets/images/backgrounds/hoverboard.png)"
                      side="left"
                      footer={<HashLink to="#jetpack" className="navigationItem"><KeyboardArrowDown/></HashLink>}/>
        <ActivityInfo content={contentJetpack} name="jetpack"
                      backgroundImage="url(assets/images/backgrounds/jetpack.jpg)"
                      side="right" footer={<HashLink to="#jetovator" className="navigationItem"><KeyboardArrowDown/></HashLink>}/>
        <ActivityInfo content={contentJetovator} name="jetovator"
                      backgroundImage="url(assets/images/backgrounds/jetovator.jpeg)"
                      side="left" footer={<HashLink to="#bumperball" className="navigationItem"><KeyboardArrowDown/></HashLink>}/>
        <ActivityInfo content={contentBumperball} name="bumperball"
                      backgroundImage="url(assets/images/backgrounds/bumperball.jpg)"
                      side="right"
                      footer={<HashLink to="#footer" className="navigationItem"><KeyboardArrowDown/></HashLink>}/>
        <Footer/>
        {/*<MessengerAssistance/>*/}
        <LanguageSelector/>
    </Box>;
}
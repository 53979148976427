import {useTranslation} from "react-i18next";
import {Box, Grid, Typography} from "@mui/material";
import theme from "../theme";
import {Facebook, Phone, Email} from "@mui/icons-material";

export default function Footer() {
    const {t} = useTranslation();

    return <Box component="footer" id="footer" sx={{
        background: 'linear-gradient(to bottom right, #565654, #121210)',
        pb: 4,
        '& > div': {
            padding: 1
        },
        'ul li': {
            listStyle: 'none'
        },
        'a': {
            color: theme.palette.common.white,
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    }}>
        <iframe width="100%" height="400" id="gmap_canvas"
                src="https://maps.google.com/maps?q=klein%20strand,%20jabbeke&t=&z=13&ie=UTF8&iwloc=&output=embed"/>
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <p>{t('__Info_Paragraph_1')}</p>
                <p>
                    <strong>Klein Strand</strong><br/>
                    Varsenareweg 29<br/>
                    8490 Jabbeke (Brugge)
                </p>
                <p>{t('__Info_Paragraph_2')}</p>
                <p>{t('__BTW')}: BE 0524643801<br/>BNP Paribas Fortis: BE60 0017 2079 6770 (BIC
                    GEBABEBB)</p>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ul>
                    <li><Facebook/> <a href="https://www.facebook.com/flyboardvlaanderen"
                                       target="_blank">flyboardvlaanderen</a></li>
                    <li><Email/> <a
                        href="mailto:info@flyboardvlaanderen.be">info@flyboardvlaanderen.be</a></li>
                    <li><Typography component="a" href={`tel:0032477398118`}><Phone/> +32 477 39 81 18</Typography></li>
                </ul>
                <div className="fb-page" data-href="https://www.facebook.com/flyboardvlaanderen"
                     data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"
                     data-show-facepile="true" data-show-posts="false">
                    <div className="fb-xfbml-parse-ignore">
                        <blockquote cite="https://www.facebook.com/flyboardvlaanderen"><a
                            href="https://www.facebook.com/flyboardvlaanderen">Flyboard vlaanderen</a>
                        </blockquote>
                    </div>
                </div>
            </Grid>
        </Grid>

        {/*<HashLink to="#home"><KeyboardArrowUp/></HashLink>*/}
    </Box>;
}
import {Box, IconButton} from "@mui/material";
import i18n from "i18next";

export default function LanguageSelector() {
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    }

    return <Box sx={{
        position: 'fixed',
        bottom: '10px',
        left: '20px',
        '& img': {
            border: '2px solid #fff',
            borderRadius: '5px',
            height: '20px'
        }
    }}>
        <IconButton onClick={() => changeLanguage('nl')}
                    component="span">
            <img src="/assets/images/nl.png" alt="nl"/>
        </IconButton>
        <IconButton onClick={() => changeLanguage('fr')}
                    component="span">
            <img src="/assets/images/fr.png" alt="fr"/>
        </IconButton>
    </Box>;
};

import React from "react";
import BasicAppBar from "../components/BasicAppBar";
import {Appointments as AppointmentsForm} from "@timtowtdi/wata.appointments";
import {useGetServices} from "../hooks/useGetServices";
import {useGetAvailabilities} from "../hooks/useGetAvailabilities";
import i18next from "i18next";

export default function Appointments() {
    const {services} = useGetServices(true);
    const {availabilities} = useGetAvailabilities();

    return <>
        <BasicAppBar/>
        <AppointmentsForm language={i18next.language}
                          services={services}
                          availabilities={availabilities}/>
    </>;
}
import {Box, Container} from "@mui/material";
import LanguageSelector from "../components/LanguageSelector";
import BasicAppBar from "../components/BasicAppBar";
import {useTranslation} from "react-i18next";
import theme from "../theme";

export default function Shows() {
    const {t} = useTranslation();

    return <>
        <BasicAppBar/>
        <Box className="shows" sx={{
            color: theme.palette.primary.contrastText,
            background: 'linear-gradient(to bottom right, #565654, #121210)',
            p: 12,
            '& video': {
                margin: '0 auto',
                width: '60%'
            },
            '& a': {
                color: theme.palette.common.white,
                textDecoration: 'underline',
                '&:hover': {
                    textDecoration: 'none'
                }
            },
            '& img': {
                float: 'right',
                width: '60px'
            }
        }}>
            <Container>
                <h1>{t('section_shows_title')}</h1>
                <p>{t('section_shows_paragraph_1')}</p>
                <video autoPlay controls muted loop>
                    <source src="/assets/videos/flyboard-show-web.mp4" type="video/mp4"/>
                </video>
                <p>{t('section_shows_paragraph_2')}</p>
                <ul>
                    <li><a
                        href="https://www.hln.be/regio/nieuws-uit-kortrijk/flyboarding-in-nieuw-vtm-programma-a2176035/"
                        target="_blank" rel="noopener noreferrer">{t('section_shows_article_1')}</a></li>
                    <li><a href="https://deredactie.be/cm/vrtnieuws/videozone/Gezienoptv/1.2381262"
                           target="_blank" rel="noopener noreferrer">{t('section_shows_article_2')}</a></li>
                    <li><a href="https://www.een.be/programmas/iedereen-beroemd/de-figuur-flyboarding-dimitri"
                           target="_blank" rel="noopener noreferrer">{t('section_shows_article_3')}</a></li>
                    <li>{t('section_shows_article_4')}</li>
                    <li>...</li>
                </ul>
                <p>{t('section_shows_paragraph_3')}</p>
                <ul>
                    <li>{t('section_shows_openings_1')}</li>
                    <li>{t('section_shows_openings_2')}</li>
                    <li>{t('section_shows_openings_3')}</li>
                    <li>...</li>
                </ul>
                <img src="/assets/images/guinness.png" alt="Guinness World Records"/>
                <p>{t('section_shows_paragraph_4')}</p>
                <p>{t('section_shows_paragraph_5')}</p>
            </Container>
            <LanguageSelector/>
            {/*<MessengerAssistance/>*/}
        </Box>
    </>;
}

// /*
// <section>
// 	<p><?php $t->__('__Shows_Paragraph_Contact_1'); echo " "; $t->__('__Shows_Paragraph_Contact_2'); ?> (<a href="mailto:<?php $t->__('__email_adress'); ?>"><?php $t->__('__email_adress'); ?></a>)</p>
//     <form action="mailto:<?php $t->__('__email_adress'); ?>" method="post" enctype="text/plain"></form>
// </section>
//  */
import {useEffect, useState} from "react";
import {getServicesRequest} from "../api/getServicesRequest";
import {Service} from "@timtowtdi/wata.domain.wata";

export const useGetServices = (active: boolean) => {
    const [services, setServices] = useState<Service[]>([]);

    useEffect(() => {
        const getServices = async () => {
            setServices(await getServicesRequest(active));
        }

        getServices();
    }, []);

    return {services};
}
import {useEffect, useState} from "react";
import {getAvailabilitiesRequest} from "../api/getAvailabilitiesRequest";
import {Availability} from "@timtowtdi/wata.domain.wata";

export const useGetAvailabilities = (serviceName?: string | undefined, amount: number | undefined = undefined) => {
    const [availabilities, setAvailabilities] = useState<Availability[]>([]);

    const getAvailabilities = async () => {
        let getAvailabilities = await getAvailabilitiesRequest(serviceName);
        setAvailabilities(getAvailabilities.filter(availability => amount === undefined || availability.timeslots.flat().reduce((sum, timeslot) => sum + timeslot.capacity, 0) >= amount));
    }

    useEffect(() => {
        getAvailabilities();
    }, []);

    return {availabilities, getAvailabilities};
}
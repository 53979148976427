import {
    Box,
    AppBar,
    Toolbar,
    ListItemButton,
    ListItem,
    List,
    Drawer,
    Container, IconButton
} from "@mui/material";
import React, { useState } from "react";
import theme from "../theme";
import {useTranslation} from "react-i18next";
import {Menu} from "@mui/icons-material";
import { HashLink } from "react-router-hash-link";

type NavigationItem = {
    title: string;
    link: string;
};

const navigationItems: NavigationItem[] = [
    {
        title: 'navigation_title_home',
        link: '/#home'
    },
    {
        title: 'navigation_title_flyboard',
        link: '/#flyboard'
    },
    {
        title: 'navigation_title_hoverboard',
        link: '/#hoverboard'
    },
    {
        title: 'navigation_title_shows',
        link: '/shows',
    },
    {
        title: 'navigation_title_info',
        link: '/#footer',
    },
    {
        title: 'navigation_title_reservation',
        link: '/appointment'
    },
];

/*
  "navigation_title_activities": "Activités",
  "navigation_title_jetpack": "Jetpack",
  "navigation_title_jetovator": "Jetovator",
  "navigation_title_bumperball": "Bumper Ball",

		<li class="dropdown"><a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><?php $t->__('__Nav_Activities'); ?> <span class="caret"></span></a>
		<ul>
			<li><a href="index.php?lang=<?php $t->__('__lang_link'); ?>#flyboard"><?php $t->__('__Nav_Flyboard'); ?></a></li>
			<li><a href="index.php?lang=<?php $t->__('__lang_link'); ?>#hoverboard"><?php $t->__('__Nav_Hoverboard'); ?></a></li>
			<li><a href="index.php?lang=<?php $t->__('__lang_link'); ?>#jetpack"><?php $t->__('__Nav_Jetpack'); ?></a></li>
			<li><a href="index.php?lang=<?php $t->__('__lang_link'); ?>#jetovator"><?php $t->__('__Nav_Jetovator'); ?></a></li>
			<li><a href="index.php?lang=<?php $t->__('__lang_link'); ?>#bumperball"><?php $t->__('__Nav_Bumperball'); ?></a></li>
		</ul>
		</li>
	</ul>
</nav>

<nav class="navbar navbar-expand-lg fixed-top">
    <div class="container">
        <a class="navbar-brand" href="index.php?lang=<?php $t->__('__lang_link'); ?>#home"><?php $t->__('__Nav_Home'); ?></a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><?php $t->__('__Nav_Activities'); ?></a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" href="index.php?lang=<?php $t->__('__lang_link'); ?>#flyboard"><?php $t->__('__Nav_Flyboard'); ?></a></li>
                        <li><a class="dropdown-item" href="index.php?lang=<?php $t->__('__lang_link'); ?>#hoverboard"><?php $t->__('__Nav_Hoverboard'); ?></a></li>
                        <li><a class="dropdown-item" href="index.php?lang=<?php $t->__('__lang_link'); ?>#jetpack"><?php $t->__('__Nav_Jetpack'); ?></a></li>
                        <li><a class="dropdown-item" href="index.php?lang=<?php $t->__('__lang_link'); ?>#bumperball"><?php $t->__('__Nav_Bumperball'); ?></a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
 */

export default function BasicAppBar() {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    }

    function toolbar() {
        return (
            <Container maxWidth="md" sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit'
                }
            }}>
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'flex'
                    },
                    flexGrow: 1,
                    justifyContent: 'space-between'
                }}>
                    {navigationItems.map(navigationItem => (
                        <HashLink to={navigationItem.link}
                                  key={navigationItem.title}>{t(navigationItem.title)}</HashLink>
                    ))}
                </Box>
                <Box sx={{
                    display: {
                        xs: 'flex',
                        sm: 'none'
                    }
                }}>
                    <IconButton
                        size="large"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleDrawer(true)}
                        color="inherit"
                    >
                        <Menu/>
                    </IconButton>
                    <Drawer
                        anchor="top"
                        open={open}
                        onClose={toggleDrawer(false)}>
                        <Box
                            sx={{width: 'auto', backgroundColor: theme.palette.primary.main}}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}>
                            <List>
                                {navigationItems.map(navigationItem => (
                                    <ListItem key={navigationItem.title} disablePadding>
                                        <ListItemButton sx={{
                                            borderBottom: '1px solid #fff'
                                        }}>
                                            <HashLink to={navigationItem.link}
                                                      key={navigationItem.title}
                                                      style={{
                                                          color: theme.palette.common.white,
                                                          textDecoration: 'none'
                                                      }}>{t(navigationItem.title)}</HashLink>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Drawer>
                </Box>
            </Container>
        );
    }

    return <Box sx={{flexGrow: 1}}>
        <AppBar position="fixed">
            <Toolbar>
                {toolbar()}
            </Toolbar>
        </AppBar>
    </Box>;
}
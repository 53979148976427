import {useEffect, useState} from "react";
import {getAppointmentRequest} from "../api/getAppointmentRequest";
import {Appointment} from "@timtowtdi/wata.domain.wata";

export const useGetAppointment = (id: string) => {
    const [appointment, setAppointment] = useState<Appointment>();

    const getAppointment = async () => {
        setAppointment(await getAppointmentRequest(id));
    }

    useEffect(() => {
        getAppointment();
    }, []);

    return {appointment, getAppointment};
}
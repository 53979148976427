import React, {Fragment, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {useGetAppointment} from "../hooks/useGetAppointment";
import {VerifiedOutlined} from "@mui/icons-material";
import {Box, Button, Container, Paper, Stack, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import theme from "../theme";
import BasicAppBar from "../components/BasicAppBar";

export default function Appointment() {
    const {id} = useParams();
    const {t} = useTranslation();
    const isBelowSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

    const {appointment, getAppointment} = useGetAppointment(id!);

    useEffect(() => {
        getAppointment();
    }, [getAppointment]);

    const openPaymentWindow = () => {
        if (appointment != undefined && appointment?.checkoutUrl !== null) {
            window.location.href = appointment.checkoutUrl;
        }
    }

    return <Fragment>
        <BasicAppBar/>
        <Box sx={{
            px: {
                xs: 0,
                sm: 10
            }
        }}>
            <Container component={Paper}
                       maxWidth="lg"
                       square={isBelowSmBreakpoint}
                       sx={{
                           my: 10,
                           p: 4
                       }}>
                {appointment?.status === 'payment_open' ?
                    <Stack justifyContent="center"
                           alignItems="center">
                        <Typography sx={{mb: 4}}>{t('PAY_WITH_MOLLIE')}</Typography>
                        {appointment?.checkoutUrl !== null ? <Button onClick={openPaymentWindow} component={Link} variant="contained" to={appointment?.checkoutUrl} target="_blank" rel="noopener noreferrer">{t('PAYMENT_ONLINE')}</Button> : '' }
                    </Stack> :
                    <Stack justifyContent="center"
                           alignItems="center">
                        <VerifiedOutlined color="success"
                                          sx={{
                                              fontSize: {
                                                  xs: 120,
                                                  md: 200
                                              }
                                          }}/>
                        <Typography variant="h1" sx={{
                            mb: 2,
                            mt: 2,
                            fontSize: {
                                xs: '1.4rem',
                                md: '2.4rem'
                            }
                        }}>{t('APPOINTMENT_COMPLETED')}</Typography>
                        <Typography sx={{
                            mb: 10,
                            textAlign: 'center',
                            fontSize: {
                                xs: '.8rem',
                                md: '1rem'
                            }
                        }}>{t('APPOINTMENT_COMPLETED_NOTE', {date: appointment?.date.format("LL")})}</Typography>
                    </Stack>}
            </Container>
        </Box>
    </Fragment>;
}
import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        background: {
            default: '#383D3B',
            paper: '#fdffff'
        },
        primary: {
            main: '#D81D2C',
            contrastText: '#fdffff',
        },
        secondary: {
            main: '#570000',
            contrastText: '#fdffff',
        },
    },
    typography: {
        fontFamily: [
            '"Trebuchet MS"',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: '2.4rem'
        },
        h2: {
            fontSize: '1.6rem'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#ffffff'
                }
            }
        }
    }
});

export default theme;
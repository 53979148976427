import axios from "axios";
import moment from "moment";
import {Price, Service} from "@timtowtdi/wata.domain.wata";

export const getServicesRequest = async (active: boolean): Promise<Service[]> => {
    let url = `services.php${active ? '?active=true' : ''}`;

    const {data} = await axios.get(url);

    return (data || []).map((serviceResource: ServiceResource): Service => {
        function determineImage(name: string) {
            switch (name) {
                case 'FLYBOARD':
                    return 'flyboard.webp';
                case 'AQUAPARK':
                    return 'aquapark17.webp';
                case 'STANDUP_PADDLE':
                    return 'sup.webp';
                case 'HOVERBOARD':
                    return 'hoverboard.jpg';
                case 'JETOVATOR':
                    return 'jetovator.jpg';
                case 'JETPACK':
                    return 'jetpack.jpg';
                default:
                    return '';
            }
        }

        return {
            name: serviceResource.name,
            image: determineImage(serviceResource.name),
            capacity: serviceResource.capacity,
            duration: moment(serviceResource.duration, 'HH:mm:ss'),
            price: new Price(serviceResource.price)
        }
    });
}

interface ServiceResource {
    name: string;
    capacity: number;
    duration: string;
    price: number;
}
